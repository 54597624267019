<template>
  <nav class="navbar min-w-1400" v-if="!isHomeRoute">
    <div class="secondBox max-w-1400">
      <div class="logo">
        <img
          :src="require('@/page/lovelymiuposts/components/img/logo.png')"
          class="logoImg"
        />
        <span class="title">LovelyMiuposts</span>
        <!-- <div class="titleBox">
          <span>PROSPERITY AND GROWTH</span>
          <span>COMMERCIAL JOINT STOCK BANK</span>
        </div> -->
      </div>
      <ul class="nav-links">
        <!-- <li :class="{ 'active-link': isActive('/') }" class="Li1">
          <router-link to="/" class="nav-link1">
            <span class="routeText1">ประวัติบริษัท</span>
          </router-link>
        </li> -->
        <!-- <li :class="{ 'active-link': $route.path === '/about' }" class="Li2">
          <router-link to="/about" class="nav-link2">
            <span class="routeText2">ผลิตภัณฑ์ของเรา</span>
          </router-link>
        </li> -->
        <li
          class="dropdown Li2"
          @mouseover="changeImage(true)"
          @mouseout="changeImage(false)"
        >
          <!-- 添加下拉图标 -->
          <router-link
            v-if="$route.path === '/first1'"
            to="/first1"
            class="nav-link2"
          >
            <span class="dropdown-toggle routeText2">English</span>
            <img id="image" :src="image1" alt="Image" />
          </router-link>

          <router-link v-if="$route.path === '/'" to="/" class="nav-link2">
            <span class="dropdown-toggle routeText2">ภาษา</span
            ><img id="image" :src="image1" alt="Image" />
          </router-link>

          <ul class="dropdown-menu" ref="dropdownMenu">
            <li style="color: red">
              <router-link
                v-if="$route.path === '/first1'"
                to="/"
                class="nav-link2"
              >
                <span class="dropdown-toggle routeText6">ภาษา</span>
              </router-link>

              <router-link
                v-if="$route.path === '/'"
                to="/first1"
                class="nav-link2"
              >
                <span class="dropdown-toggle routeText6">English</span>
              </router-link>
              <!-- <a
                href="http://qmoney.gomatrixcompany.com/"
                class="nav-link2"
                target="_blank"
              >
                <span class="dropdown-toggle routeText6">English</span>
              </a> -->
            </li>
          </ul>
        </li>
        <!-- <li :class="{ 'active-link': $route.path === '/question' }" class="Li3">
          <router-link to="/question" class="nav-link3">
            <span class="routeText3">ศูนย์ช่วยเหลือ</span>
          </router-link>
        </li> -->
        <!-- <li :class="{ 'active-link': $route.path === '/protocol' }" class="Li4">
          <router-link to="/protocol" class="nav-link4">
            <span class="routeText4">ข้อตกลงความเป็นส่วนตัว</span>
          </router-link>
          <a href="https://cltiw.chookiatcl.com/help/privacy_policy" class="nav-link3" target="_blank">
            <span class="routeText4">ข้อตกลงความเป็นส่วนตัว</span>
          </a>
        </li> -->
      </ul>
    </div>
    <!-- <div style="height: 130px"></div> -->
  </nav>
</template>

<script>
import image1 from "@/page/lovelymiuposts/components/img/0_0.png";
import image2 from "@/page/lovelymiuposts/components/img/0.png";
export default {
  data() {
    return {
      link1Color: "white",
      image1: image1,
      image2: image2,
      url1: image1,
      url2: image2,
    };
  },
  computed: {
    isHomeRoute() {
      return this.$route.path === "/first1/app-ads.txt";
    },
  },
  methods: {
    isActive(route) {
      console.log(this.$route.path);
      return this.$route.path === route;
    },
    changeImage(isHovered) {
      if (isHovered) {
        this.image1 = this.url2; // 鼠标悬浮时显示的图片
      } else {
        this.image1 = this.url1; // 鼠标离开时显示的图片
      }
    },
  },
};
</script>
<style scoped>
.navbar {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: "";
  height: 90px;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.secondBox {
  display: flex;
  width: 100%;
  justify-content: space-between;
  /* height: 90px; */
  padding: 0 15px;
  background-color: #ffffff;
  /* box-shadow: 0px 2px 28px rgba(50, 55, 71, 0.10); */
}

.logo {
  font-weight: bold;
  color: black;
  display: flex;
  align-items: center;
}

.logoImg {
  width: 60px;
  height: 60px;
  margin-left: 60px;
}

.title {
  width: 500px;
  /* height: 24px; */
  /* line-height: 23px; */
  /* padding-left: 17px; */
  /* text-transform: uppercase; */
  line-height: 35px;
  height: 90px;
  line-height: 90px;
  color: #633d0a;
  text-align: center;
  font-family: "Thonburi";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
}

.routeText1,
.routeText2,
.routeText3,
.routeText4,
.routeText5 {
  color: #633d0a;
  font-family: "Thonburi";
  font-size: 18px;
  font-weight: 700;
  /* line-height: 29px; */
  /* text-transform: uppercase; */
}

.nav-link1,
.nav-link2,
.nav-link3,
.nav-link4,
.nav-link5 {
  text-decoration: none;

  margin: 0 auto;
}

.nav-link2 {
  display: flex;
}

.nav-link2 img {
  width: 22px;
  height: 22px;
  margin-top: 36px;
}

.nav-links {
  list-style: none;
  display: flex;
  align-items: center;
  /* 垂直居中对齐 */
  justify-content: center;
  /* 水平居中对齐 */
  margin-right: 60px;
}

.nav-links li {
  display: flex;
  /* padding: 6px; */
  background-size: 100% 100%;
  background-repeat: no-repeat;
  /* height: 39px; */
  padding-left: 4px;
  padding-right: 4px;
  line-height: 90px;
  align-items: center;
}

.Li1 {
  width: 150px;
}

.Li2 {
  width: 190px;
  background-color: #ffcc48;
  height: 70px;
  border-radius: 25px;
}

.Li3 {
  width: 160px;
}

.Li4 {
  width: 240px;
  /* 这个如果给margin-right底部可能会出现边距的问题 */
}

.Li5 {
  width: 210px;
  /* 这个如果给margin-right底部可能会出现边距的问题 */
}

.active-link {
  /* border-color: #6e44fd;
  background-color: #6e44fd; */
  /* border-radius: 40px; */
  /* border-radius: 10px; */
}

.active-link .routeText1,
.active-link .routeText2,
.active-link .routeText3,
.active-link .routeText4,
.active-link .routeText5 {
  font-family: Inter-Extra Bold;
  color: #ffffff;
}

.title {
  /* border: 1px solid red; */
  /* padding-left: 110px; */
  width: 220px;
}

.titleBox {
  display: flex;
  flex-direction: column;
  padding-left: 160px;
  font-size: 18px;
  font-family: DM Sans-Bold;
  font-weight: 600;
  color: #292b33;
  /* border: 1px solid red; */
}

.secondBox {
  padding: 0 100px 0 100px;
  /* border-bottom: 1px solid #656a6c; */
}

.dropdown {
  position: relative;
}

.dropdown-toggle {
  cursor: pointer;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  font-family: "Thonburi";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  padding: 5px 0;
  min-width: 190px;
  border-radius: 25px;
  background: #ffcc48;
  margin-top: 10px;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.dropdown-menu li {
  height: 60px;
  line-height: 60px;
}

.dropdown-menu li a {
  font-size: 14px;
  text-decoration: none;
  font-family: SF Pro-Semibold;
  /* font-weight: 550; */
  /* margin-top: 10px; */
}

.routeText6,
.routeText7,
.routeText8,
.routeText9,
.routeText10 {
  width: 100%;
  text-align: center;
  color: #633d0a;
  font-family: "Thonburi";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}
.active-link1 .routeText6,
.active-link1 .routeText7,
.active-link1 .routeText8,
.active-link1 .routeText9,
.active-link1 .routeText10 {
  color: #633d0a;
}

.dropdown-icon {
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #000;
  /* 设置倒三角形的颜色 */
  border-bottom: none;
  /* 去除下边框 */
  margin-left: 5px;
  vertical-align: middle;
}

.dropdown-item-line {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  width: 80%;
  margin-left: 10%;
}
</style>
